<template lang="pug">
	main#app
		article#page
			router-view
</template>

<script>
export default {
	name: 'template-app',
	metaInfo() {
		return {
			title: 'Base Vuejs',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
}
</script>

<style lang="stylus" scoped src="./App.styl"></style>
