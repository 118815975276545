import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		sideMenu: false,
		asideInfos: false,
		couponModal: false,
	},
	mutations: {
		toggleAside(state, payload){
			if(!payload) {
				state.asideInfos = payload
				state.couponModal = payload
			} else {
				if(payload == 'couponModal') 
					state.couponModal = true
				else if(payload == 'asideInfos') 
					state.asideInfos = true
			}
		},
	},
	actions: { },
	modules: { }
})
